<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập tên cơ sở...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Cơ sở phục hồi đa chức năng">
                <template slot="actionBefore">
                </template>
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <province-select :model="filterFormModel" attribute="province_id"   :is-filter='true'  class="mr-2"  @change='load' />
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :params='params' :prime="isPrime() || isTW() || isUsaid()" :totalCount="itemCount" class="mr-1"/>
                    <g-button class="ml-1 btn btn-gray btn-sm mr-1 ml-1" icon="fa fa-file-excel" v-if="isSub()" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import v-if="permissions('org-multiple_create') && isSub()"  :params='params'  class="mr-1"/>
                    <g-button class="ml-2" v-if="permissions('org-multiple_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(province_id)="{item}">
                    <div ><span class="small text-gray-999">Tên cơ sở PHCN: </span>{{item.name}}</div>
                    <div ><span class="small text-gray-999">Địa chỉ: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id]}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id]}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id]}}</span>
                    </div>

                </template>
                <template #cell(info)="{item}">
                    <div v-if="params.stages[item.id]">
                        <div v-for="(item1, index) in params.stages[item.id]" :key="index" >
                            <i>- Ngày  {{item1.start_time | dateFormat}}  </i> 
                            <span v-if="item1.type"><i> loại hình hỗ trợ: </i><span class='text-danger'> {{item1.type }}</span></span>
                        </div>
                    </div>
                </template>
                <template #cell(action)="{item}">
                    <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('org-multiple_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil" content="" @click="update(item.id)" />
                    <g-button  v-if="permissions('org-multiple_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="org-multiple" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Tên cơ sở PHCN" :required='true' :model="formModel" attribute="name"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Năm thành lập" :required='true'  type="number" :model="formModel" attribute="founded_year" placeholder="YYYY" :errors="formErrors" ></form-input>
                </div>
            </div> 

            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
            <org-multiple-stage :view="false" label="Giai đoạn" :model="formModel" :errors="formErrors" attribute="items" />
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Tên cơ sở PHCN" disabled :model="formModel" attribute="name"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Năm thành lập"  disabled  type="number" :model="formModel" attribute="founded_year" placeholder="YYYY" :errors="formErrors" ></form-input>
                </div>
            </div> 

            <form-group-address :provinceDisabled="true"  :districtDisabled="true" :wardDisabled="true" class="pr-1" disabled :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
            <org-multiple-stage :view="true" label="Giai đoạn" :model="formModel" :errors="formErrors" attribute="items" />
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok" v-if="!view">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import ClientSelect from '@/components/ClientSelect';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import Log from "@/components/Log";
    import OrgMultipleStage from './OrgMultipleStage';
    import Import from './OrgMultipleImport';
    import Export from './OrgMultipleExport';
    import ImportMinxi from "@/mixins/Template";

    export default {
        components: {ClientSelect, ProvinceSelect, Log, OrgMultipleStage, Import, Export},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                service: '/org-multiple',
                reloadBus: "reloadOrg",
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    type: null,
                    client_sub_id: null,

                },
                defaultFormModel: {
                    name: null,
                    code: null,
                    items: [],
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                },
                showFormView: false,
                //template mẫu
                indexRow: 4,
                templateName: 'co-so-phuc-hoi-da-chuc-nang.xlsx',
                headers: [
                    {header: 'name', key: 'name', label: 'Tên đầy đủ của cơ sở PHCN*', width: 30, desc: "Nhập định dạng chữ (Theo Tên Đăng ký chính thức)"},
                    {header: 'founded_year', key: 'founded_year', label: 'Năm thành lập*', width: 30, desc: "Nhập định dạng năm (yyyy)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 30, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'start_time', key: 'start_time', label: 'Thời gian hỗ trợ áp dụng phương thức đa chuyên ngành*', width: 30, desc: "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)"},
                    {header: 'type', key: 'type', label: 'Loại hình hỗ trợ', width: 30, desc: "Nhập định dạng chữ"},
                ],
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'province_id', label: "Thông tin cơ sở PHCN"},
                    {key: 'founded_year', label: "Năm thành lập"},
                    {key: 'info', label: "Thời gian áp dụng hỗ trợ phương thức đa chuyên ngành"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    if (this.beforeUpdate) {
                        this.beforeUpdate(response.data);
                    }
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin: " + this.formModel.name;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
        }
    }
</script>