<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';
    export default {
        props: ['filter', 'totalCount', 'types', "prime"],
        mixins: [Export],
        data() {
            return {
                service: '/org-multiple',
                fileName: `co-so-phuc-hoi-da-chuc-nang`,
                headers: this.prime ? [
                    {header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 26},
                    {header: 'name', key: 'name', label: 'Tên đầy đủ tổ chức*', width: 30},
                    {header: 'founded_year', key: 'founded_year', label: 'Năm thành lập*', width: 30},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 15},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 15},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 15},
                    {header: 'start_time', key: 'start_time', label: 'Thời gian hỗ trợ áp dụng phương thức đa chuyên ngành*', width: 20},
                    {header: 'type', key: 'type', label: 'Loại hình hỗ trợ', width: 30},
                ] : [
                    {header: 'name', key: 'name', label: 'Tên đầy đủ tổ chức*', width: 30},
                    {header: 'founded_year', key: 'founded_year', label: 'Năm thành lập*', width: 30},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 15},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 15},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 15},
                    {header: 'start_time', key: 'start_time', label: 'Thời gian hỗ trợ áp dụng phương thức đa chuyên ngành*', width: 20},
                    {header: 'type', key: 'type', label: 'Loại hình hỗ trợ', width: 30},
                ],
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);


                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["name"] = "Nhập định dạng chữ (Theo Tên Đăng ký chính thức)";
                row["province_id"] = "Nhập định dạng chữ (Tỉnh Quảng Trị,Tỉnh Thừa Thiên Huế,Tỉnh Quảng Nam,Tỉnh Bình Định,Tỉnh Kon Tum,Tỉnh Bình Phước,Tỉnh Tây Ninh,Tỉnh Đồng Nai)";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row["founded_year"] = "Nhập định dạng năm (yyyy)";
                row["start_time"] = "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)";
                row["type"] = "Nhập định dạng chữ";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };


            },
            addRow(item, params) {
                let row = {
                    client_id: params.clients && params.clients[item.client_id] ? params.clients[item.client_id].short_name : '',
                    name: item.name,
                    founded_year: item.founded_year,
                    province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id] : '',
                    district_id: params.districts && params.districts[item.district_id] ? params.districts[item.district_id] : '',
                    ward_id: params.wards && params.wards[item.ward_id] ? params.wards[item.ward_id] : '',
                    // type: this.getTypes(this.getStage(params.stageExports, item.id) ? this.getStage(params.stageExports, item.id).type : null),
                    // start_time: this.getStage(params.stageExports, item.id) ? this.getStage(params.stageExports, item.id).start_time : '',
                };
                if(params.stages[item.id].length > 0){
                    row['start_time'] = moment(params.stages[item.id][0].start_time).format('DD/MM/YYYY');
                    row['type'] = params.stages[item.id][0].type;
                }
                return row;
            },
            getStage(stageExports, ID) {
                let st = stageExports[ID];
                return st ? st : null;
            },
            getTypes(type) {
                let text = "";
                if (type != null) {
                    for (let item of type) {
                        if (this.$params.org.typeInterventionOptions.find(o => o.value == item)) {
                            text = text + this.$params.org.typeInterventionOptions.find(o => o.value == item).text + "; ";
                        }
                    }
                }
                return text;
            },
        }
    }
</script>